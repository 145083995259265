export const eraData =
  [{"taiwan":"民國115", "bc":"2026", "old":"丙午", "zodiac":"馬"},
      {"taiwan":"民國114", "bc":"2025", "old":"乙巳", "zodiac":"蛇"},
      {"taiwan":"民國113", "bc":"2024", "old":"甲辰", "zodiac":"龍"},
      {"taiwan":"民國112", "bc":"2023", "old":"癸卯", "zodiac":"兔"},
      {"taiwan":"民國111", "bc":"2022", "old":"壬寅", "zodiac":"虎"},
      {"taiwan":"民國110", "bc":"2021", "old":"辛丑", "zodiac":"牛"},
      {"taiwan":"民國109", "bc":"2020", "old":"庚子", "zodiac":"鼠"},
      {"taiwan":"民國108", "bc":"2019", "old":"己亥", "zodiac":"豬"},
      {"taiwan":"民國107", "bc":"2018", "old":"戊戌", "zodiac":"狗"},
      {"taiwan":"民國106", "bc":"2017", "old":"丁酉", "zodiac":"雞"},
      {"taiwan":"民國105", "bc":"2016", "old":"丙申", "zodiac":"猴"},
      {"taiwan":"民國104", "bc":"2015", "old":"乙未", "zodiac":"羊"},
      {"taiwan":"民國103", "bc":"2014", "old":"甲午", "zodiac":"馬"},
      {"taiwan":"民國102", "bc":"2013", "old":"癸巳", "zodiac":"蛇"},
      {"taiwan":"民國101", "bc":"2012", "old":"壬辰", "zodiac":"龍"},
      {"taiwan":"民國100", "bc":"2011", "old":"辛卯", "zodiac":"兔"},
      {"taiwan":"民國99", "bc":"2010", "old":"庚寅", "zodiac":"虎"},
      {"taiwan":"民國98", "bc":"2009", "old":"己丑", "zodiac":"牛"},
      {"taiwan":"民國97", "bc":"2008", "old":"戊子", "zodiac":"鼠"},
      {"taiwan":"民國96", "bc":"2007", "old":"丁亥", "zodiac":"豬"},
      {"taiwan":"民國95", "bc":"2006", "old":"丙戌", "zodiac":"狗"},
      {"taiwan":"民國94", "bc":"2005", "old":"乙酉", "zodiac":"雞"},
      {"taiwan":"民國93", "bc":"2004", "old":"甲申", "zodiac":"猴"},
      {"taiwan":"民國92", "bc":"2003", "old":"癸未", "zodiac":"羊"},
      {"taiwan":"民國91", "bc":"2002", "old":"壬午", "zodiac":"馬"},
      {"taiwan":"民國90", "bc":"2001", "old":"辛巳", "zodiac":"蛇"},
      {"taiwan":"民國89", "bc":"2000", "old":"庚辰", "zodiac":"龍"},
      {"taiwan":"民國88", "bc":"1999", "old":"己卯", "zodiac":"兔"},
      {"taiwan":"民國87", "bc":"1998", "old":"戊寅", "zodiac":"虎"},
      {"taiwan":"民國86", "bc":"1997", "old":"丁丑", "zodiac":"牛"},
      {"taiwan":"民國85", "bc":"1996", "old":"丙子", "zodiac":"鼠"},
      {"taiwan":"民國84", "bc":"1995", "old":"乙亥", "zodiac":"豬"},
      {"taiwan":"民國83", "bc":"1994", "old":"甲戌", "zodiac":"狗"},
      {"taiwan":"民國82", "bc":"1993", "old":"癸酉", "zodiac":"雞"},
      {"taiwan":"民國81", "bc":"1992", "old":"壬申", "zodiac":"猴"},
      {"taiwan":"民國80", "bc":"1991", "old":"辛未", "zodiac":"羊"},
      {"taiwan":"民國79", "bc":"1990", "old":"庚午", "zodiac":"馬"},
      {"taiwan":"民國78", "bc":"1989", "old":"己巳", "zodiac":"蛇"},
      {"taiwan":"民國77", "bc":"1988", "old":"戊辰", "zodiac":"龍"},
      {"taiwan":"民國76", "bc":"1987", "old":"丁卯", "zodiac":"兔"},
      {"taiwan":"民國75", "bc":"1986", "old":"丙寅", "zodiac":"虎"},
      {"taiwan":"民國74", "bc":"1985", "old":"乙丑", "zodiac":"牛"},
      {"taiwan":"民國73", "bc":"1984", "old":"甲子", "zodiac":"鼠"},
      {"taiwan":"民國72", "bc":"1983", "old":"癸亥", "zodiac":"豬"},
      {"taiwan":"民國71", "bc":"1982", "old":"壬戌", "zodiac":"狗"},
      {"taiwan":"民國70", "bc":"1981", "old":"辛酉", "zodiac":"雞"},
      {"taiwan":"民國69", "bc":"1980", "old":"庚申", "zodiac":"猴"},
      {"taiwan":"民國68", "bc":"1979", "old":"己未", "zodiac":"羊"},
      {"taiwan":"民國67", "bc":"1978", "old":"戊午", "zodiac":"馬"},
      {"taiwan":"民國66", "bc":"1977", "old":"丁巳", "zodiac":"蛇"},
      {"taiwan":"民國65", "bc":"1976", "old":"丙辰", "zodiac":"龍"},
      {"taiwan":"民國64", "bc":"1975", "old":"乙卯", "zodiac":"兔"},
      {"taiwan":"民國63", "bc":"1974", "old":"甲寅", "zodiac":"虎"},
      {"taiwan":"民國62", "bc":"1973", "old":"癸丑", "zodiac":"牛"},
      {"taiwan":"民國61", "bc":"1972", "old":"壬子", "zodiac":"鼠"},
      {"taiwan":"民國60", "bc":"1971", "old":"辛亥", "zodiac":"豬"},
      {"taiwan":"民國59", "bc":"1970", "old":"庚戌", "zodiac":"狗"},
      {"taiwan":"民國58", "bc":"1969", "old":"己酉", "zodiac":"雞"},
      {"taiwan":"民國57", "bc":"1968", "old":"戊申", "zodiac":"猴"},
      {"taiwan":"民國56", "bc":"1967", "old":"丁未", "zodiac":"羊"},
      {"taiwan":"民國55", "bc":"1966", "old":"丙午", "zodiac":"馬"},
      {"taiwan":"民國54", "bc":"1965", "old":"乙巳", "zodiac":"蛇"},
      {"taiwan":"民國53", "bc":"1964", "old":"甲辰", "zodiac":"龍"},
      {"taiwan":"民國52", "bc":"1963", "old":"癸卯", "zodiac":"兔"},
      {"taiwan":"民國51", "bc":"1962", "old":"壬寅", "zodiac":"虎"},
      {"taiwan":"民國50", "bc":"1961", "old":"辛丑", "zodiac":"牛"},
      {"taiwan":"民國49", "bc":"1960", "old":"庚子", "zodiac":"鼠"},
      {"taiwan":"民國48", "bc":"1959", "old":"己亥", "zodiac":"豬"},
      {"taiwan":"民國47", "bc":"1958", "old":"戊戌", "zodiac":"狗"},
      {"taiwan":"民國46", "bc":"1957", "old":"丁酉", "zodiac":"雞"},
      {"taiwan":"民國45", "bc":"1956", "old":"丙申", "zodiac":"猴"},
      {"taiwan":"民國44", "bc":"1955", "old":"乙未", "zodiac":"羊"},
      {"taiwan":"民國43", "bc":"1954", "old":"甲午", "zodiac":"馬"},
      {"taiwan":"民國42", "bc":"1953", "old":"癸巳", "zodiac":"蛇"},
      {"taiwan":"民國41", "bc":"1952", "old":"壬辰", "zodiac":"龍"},
      {"taiwan":"民國40", "bc":"1951", "old":"辛卯", "zodiac":"兔"},
      {"taiwan":"民國39", "bc":"1950", "old":"庚寅", "zodiac":"虎"},
      {"taiwan":"民國38", "bc":"1949", "old":"己丑", "zodiac":"牛"},
      {"taiwan":"民國37", "bc":"1948", "old":"戊子", "zodiac":"鼠"},
      {"taiwan":"民國36", "bc":"1947", "old":"丁亥", "zodiac":"豬"},
      {"taiwan":"民國35", "bc":"1946", "old":"丙戌", "zodiac":"狗"},
      {"taiwan":"民國34", "bc":"1945", "old":"乙酉", "zodiac":"雞"},
      {"taiwan":"民國33", "bc":"1944", "old":"甲申", "zodiac":"猴"},
      {"taiwan":"民國32", "bc":"1943", "old":"癸未", "zodiac":"羊"},
      {"taiwan":"民國31", "bc":"1942", "old":"壬午", "zodiac":"馬"},
      {"taiwan":"民國30", "bc":"1941", "old":"辛巳", "zodiac":"蛇"},
      {"taiwan":"民國29", "bc":"1940", "old":"庚辰", "zodiac":"龍"},
      {"taiwan":"民國28", "bc":"1939", "old":"己卯", "zodiac":"兔"},
      {"taiwan":"民國27", "bc":"1938", "old":"戊寅", "zodiac":"虎"},
      {"taiwan":"民國26", "bc":"1937", "old":"丁丑", "zodiac":"牛"},
      {"taiwan":"民國25", "bc":"1936", "old":"丙子", "zodiac":"鼠"},
      {"taiwan":"民國24", "bc":"1935", "old":"乙亥", "zodiac":"豬"},
      {"taiwan":"民國23", "bc":"1934", "old":"甲戌", "zodiac":"狗"},
      {"taiwan":"民國22", "bc":"1933", "old":"癸酉", "zodiac":"雞"},
      {"taiwan":"民國21", "bc":"1932", "old":"壬申", "zodiac":"猴"},
      {"taiwan":"民國20", "bc":"1931", "old":"辛未", "zodiac":"羊"},
      {"taiwan":"民國19", "bc":"1930", "old":"庚午", "zodiac":"馬"},
      {"taiwan":"民國18", "bc":"1929", "old":"己巳", "zodiac":"蛇"},
      {"taiwan":"民國17", "bc":"1928", "old":"戊辰", "zodiac":"龍"},
      {"taiwan":"民國16", "bc":"1927", "old":"丁卯", "zodiac":"兔"},
      {"taiwan":"民國15", "bc":"1926", "old":"丙寅", "zodiac":"虎"},
      {"taiwan":"民國14", "bc":"1925", "old":"乙丑", "zodiac":"牛"},
      {"taiwan":"民國13", "bc":"1924", "old":"甲子", "zodiac":"鼠"},
      {"taiwan":"民國12", "bc":"1923", "old":"癸亥", "zodiac":"豬"},
      {"taiwan":"民國11", "bc":"1922", "old":"壬戌", "zodiac":"狗"},
      {"taiwan":"民國10", "bc":"1921", "old":"辛酉", "zodiac":"雞"},
      {"taiwan":"民國9", "bc":"1920", "old":"庚申", "zodiac":"猴"},
      {"taiwan":"民國8", "bc":"1919", "old":"己未", "zodiac":"羊"},
      {"taiwan":"民國7", "bc":"1918", "old":"戊午", "zodiac":"馬"},
      {"taiwan":"民國6", "bc":"1917", "old":"丁巳", "zodiac":"蛇"},
      {"taiwan":"民國5", "bc":"1916", "old":"丙辰", "zodiac":"龍"},
      {"taiwan":"民國4", "bc":"1915", "old":"乙卯", "zodiac":"兔"},
      {"taiwan":"民國3", "bc":"1914", "old":"甲寅", "zodiac":"虎"},
      {"taiwan":"民國2", "bc":"1913", "old":"癸丑", "zodiac":"牛"},
      {"taiwan":"民國1", "bc":"1912", "old":"壬子", "zodiac":"鼠"},
      {"taiwan":"民國前1", "bc":"1911", "old":"辛亥", "zodiac":"豬"},
      {"taiwan":"民國前2", "bc":"1910", "old":"庚戌", "zodiac":"狗"},
      {"taiwan":"民國前3", "bc":"1909", "old":"己酉", "zodiac":"雞"},
      {"taiwan":"民國前4", "bc":"1908", "old":"戊申", "zodiac":"猴"},
      {"taiwan":"民國前5", "bc":"1907", "old":"丁未", "zodiac":"羊"},
      {"taiwan":"民國前6", "bc":"1906", "old":"丙午", "zodiac":"馬"},
      {"taiwan":"民國前7", "bc":"1905", "old":"乙巳", "zodiac":"蛇"},
      {"taiwan":"民國前8", "bc":"1904", "old":"甲辰", "zodiac":"龍"},
      {"taiwan":"民國前9", "bc":"1903", "old":"癸卯", "zodiac":"兔"},
      {"taiwan":"民國前10", "bc":"1902", "old":"壬寅", "zodiac":"虎"},
      {"taiwan":"民國前11", "bc":"1901", "old":"辛丑", "zodiac":"牛"},
      {"taiwan":"民國前12", "bc":"1900", "old":"庚子", "zodiac":"鼠"},
      {"taiwan":"民國前13", "bc":"1899", "old":"己亥", "zodiac":"豬"},
      {"taiwan":"民國前14", "bc":"1898", "old":"戊戌", "zodiac":"狗"},
      {"taiwan":"民國前15", "bc":"1897", "old":"丁酉", "zodiac":"雞"},
      {"taiwan":"民國前16", "bc":"1896", "old":"丙申", "zodiac":"猴"},
      {"taiwan":"民國前17", "bc":"1895", "old":"乙未", "zodiac":"羊"},
      {"taiwan":"民國前18", "bc":"1894", "old":"甲午", "zodiac":"馬"},
      {"taiwan":"民國前19", "bc":"1893", "old":"癸巳", "zodiac":"蛇"},
      {"taiwan":"民國前20", "bc":"1892", "old":"壬辰", "zodiac":"龍"},
      {"taiwan":"民國前21", "bc":"1891", "old":"辛卯", "zodiac":"兔"},
      {"taiwan":"民國前22", "bc":"1890", "old":"庚寅", "zodiac":"虎"},
      {"taiwan":"民國前23", "bc":"1889", "old":"己丑", "zodiac":"牛"},
      {"taiwan":"民國前24", "bc":"1888", "old":"戊子", "zodiac":"鼠"},
      {"taiwan":"民國前25", "bc":"1887", "old":"丁亥", "zodiac":"豬"},
      {"taiwan":"民國前26", "bc":"1886", "old":"丙戌", "zodiac":"狗"},
      {"taiwan":"民國前27", "bc":"1885", "old":"乙酉", "zodiac":"雞"},
      {"taiwan":"民國前28", "bc":"1884", "old":"甲申", "zodiac":"猴"},
      {"taiwan":"民國前29", "bc":"1883", "old":"癸未", "zodiac":"羊"}]