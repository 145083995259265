export const chineseZodiac = [
  "鼠",
  "牛",
  "虎",
  "兔",
  "龍",
  "蛇",
  "馬",
  "羊",
  "猴",
  "雞",
  "狗",
  "豬"
]