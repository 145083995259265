import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Modal from '@mui/material/Modal';

// import profile from "assets/img/faces/christian.jpg";

import styles from "assets/js/material-kit-react/views/profilePage.js";
import GridContainer from "../../components/Grid/GridContainer";
import Footer from "../../components/Footer/Footer";

const useStyles = makeStyles(styles);
const blogStyles = makeStyles({
  content: {
    maxWidth: "60rem",
    margin: "0 auto"
  }
});

export default function Blog1(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (document.title.length < 10)
      document.title = `民國110年 西元2021年 行事曆 | ${document.title}`
  }, [])
  const classes = useStyles();
  const styles = blogStyles();
  const {...rest} = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks/>}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <main className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <h1 align={"center"}>民國110年 西元2021年 行事曆</h1>
          <div className={classes.container}>
            <GridContainer justify="center">
              <img
                src={require("assets/img/blog/110年行事曆.png").default}
                style={{maxWidth: "100%"}}
                onClick={() => setOpen(true)}
              />
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: "scroll"}}
                onClick={() => setOpen(false)}
              >
                <img
                  src={require("assets/img/blog/110年行事曆.png").default}
                  style={{maxWidth: "100%", position: "absolute", left: "50%", transform: 'translateX(-50%)'}}
                />
              </Modal>
            </GridContainer>
            <GridContainer justify="center">
              <div>來源: 行政院人事行政總處</div>
            </GridContainer>
            <div className={styles.content}>
              <h3>重要節日</h3>
              <p>元旦 2021/12/31(五)-1/2(日) 放假3天</p>
              <p>農曆春節 1/29(六)-2/6(日) 放假9天 1/22(六)要補班</p>
              <p>228和平紀念日 2/26(六)-2/28(一) 放假3天</p>
              <p>兒童節&清明節 4/2(六)-4/5(二) 放假4天</p>
              <p>端午節 6/3(五)-6/5(日) 放假3天</p>
              <p>中秋節 9/9(五)-9/11(日) 放假3天 9/9(五)補假</p>
              <p>國慶日 6/3(五)-6/5(日) 放假3天</p>
              <p>2023年元旦 12/31(六)-2023/1/2(一) 放假3天</p>
            </div>
          </div>
        </div>
      </main>
      <h4 align={"center"}>相關連結</h4>
      <GridContainer justify={"center"}>
        <a href={"/111-2022-vacation-plan"}>民國111年 西元2022年 請假攻略</a>
      </GridContainer>
      <Footer/>
    </div>
  );
}
