import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// import profile from "assets/img/faces/christian.jpg";

import styles from "assets/js/material-kit-react/views/profilePage.js";
import GridContainer from "../../components/Grid/GridContainer";
import {Paper, Table, TableRow, TableBody, TableCell, TableContainer, TableHead} from "@mui/material";
import * as PropTypes from "prop-types";
import {eraData} from "../../assets/data/eraData";
import Modal from "@mui/material/Modal";
import Footer from "../../components/Footer/Footer";
import {Box, TextField} from "@material-ui/core";
import {chineseZodiac} from "../../assets/data/chineseZodiac";

const useStyles = makeStyles(styles);

export default function Blog1(props) {
  const data = [
    {"key": 1, "taiwan": 1, "bc": 2, "old": 3},
    {"key": 2, "taiwan": 1, "bc": 2, "old": 3}
  ]
  const [TWYear, setTWYear] = useState();
  const [year, setYear] = useState();
  const [zodiac, setZodiac] = useState();

  useEffect(() => {
    if (document.title.length < 10)
      document.title = `民國西元生肖農曆換算 | ${document.title}`
  }, [])
  const classes = useStyles();
  const {...rest} = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks/>}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <main className={classNames(classes.main, classes.mainRaised)}>
        <h1 align={"center"}>民國-西元-生肖-農曆歲次</h1>
        <GridContainer justify={"center"}>
          <TextField label="民國" variant="standard" value={TWYear} type={"number"} defaultValue={1}
                     inputProps={{
                       "min": 1
                     }}
                     onChange={(e) => {
                       let yearInt = parseInt(e.target.value)
                       let zodiac = (yearInt-1) % 12
                       setTWYear(yearInt)
                       setYear(1911 + parseInt(e.target.value));
                       setZodiac(getZodiac(zodiac));
                       console.log(zodiac)
                       console.log(chineseZodiac)
                     }}
          />
          <i className="fa-solid fa-arrow-right-arrow-left"
             style={{
               margin: "0 10px",
               height: "100%",
               transform: "translateY(24px)"
             }}
          ></i>

          <TextField label="西元" variant="standard" value={year} type={"number"} defaultValue={1912}
                     inputProps={{
                       "min": 1
                     }}
                     onChange={(e) => {
                       let yearInt = parseInt(e.target.value)
                       if (yearInt >= 0) {
                         setYear(yearInt)
                         setZodiac(getZodiac((yearInt-4) % 12));
                         if (yearInt - 1911 <= 0) {
                           yearInt -= 1
                         }
                         setTWYear(parseInt(yearInt - 1911));
                       }
                     }
                     }/>
          <i className="fa-solid fa-equals"
             style={{
               margin: "0 10px",
               height: "100%",
               transform: "translateY(24px)"
             }}>
          </i>
          <Box
            component="form"
            sx={{
              '& > :not(style)': {m: 1, width: '10ch'},
            }}
            noValidate
            autoComplete="off"
          >
            <TextField label="生肖" variant="standard" value={zodiac} defaultValue={"鼠"}
                       InputProps={{readOnly: true,}}/>
          </Box>
        </GridContainer>
        <GridContainer align={"center"}>
          <TableContainer>
            <Table stickyHeader sx={{minWidth: 650, maxWidth: 700}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center"> 民國</TableCell>
                  <TableCell align="center">西元</TableCell>
                  <TableCell align="center">生肖</TableCell>
                  <TableCell align="center">農曆歲次</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eraData.map((row) => (
                  <TableRow
                    key={row.key}
                  >
                    <TableCell align="center">{row.taiwan}</TableCell>
                    <TableCell align="center">{row.bc}</TableCell>
                    <TableCell align="center">{row.zodiac}</TableCell>
                    <TableCell align="center">{row.old}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GridContainer>
      </main>
      <Footer/>
    </div>
  );
}

function getZodiac(year) {
  return chineseZodiac[year]
}