import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// import profile from "assets/img/faces/christian.jpg";

import styles from "assets/js/material-kit-react/views/profilePage.js";
import GridContainer from "../../components/Grid/GridContainer";
import Modal from "@mui/material/Modal";
import Footer from "../../components/Footer/Footer";

const useStyles = makeStyles(styles);

export default function Blog1(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (document.title.length < 10)
      document.title = `民國111 西元2022 請假攻略 連假攻略 | ${document.title}`
  }, [])
  const classes = useStyles();
  const {...rest} = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks/>}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <main className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <h1 align={"center"}>民國111 西元2022 連假攻略</h1>
          <div className={classes.container}>
            <GridContainer justify="center">
              <img
                src={require("assets/img/blog/2022連假攻略.jpeg").default}
                style={{maxWidth: "100%"}}
                onClick={() => setOpen(true)}
              />
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: "scroll"}}
                onClick={() => setOpen(false)}
              >
                <img
                  src={require("assets/img/blog/2022連假攻略.jpeg").default}
                  style={{maxWidth: "100%", position: "absolute", left: "50%", transform: 'translateX(-50%)'}}
                />
              </Modal>
            </GridContainer>
            <GridContainer justify="center">
              <div>來源: 南投縣政府Facebook</div>
            </GridContainer>

            <h2>如何請連假</h2>
            <p>元旦 請4天休9天：12/25～1/2（請12/27～12/30）</p>
            <p>農曆春節 休9天：1/29～2/6</p>
            <p>228紀念日 請4天休9天：2/26～3/6（請3/1～3/4）</p>
            <p>清明節 請3天休9天：4/2～4/10（請4/6～4/8）</p>
            <p>勞動節 請4天休9天：4/30～5/8（請5/3～5/6）</p>
            <p>端午節 請4天休9天：5/28～6/5（請5/30～6/2）</p>
            <p>中秋節 請4天休9天：9/3～9/11（請9/5～9/8）</p>
            <p>國慶日 請4天休9天：10/8～10/16（請10/11～10/14）</p>
          </div>
        </div>
      </main>
      <h4 align={"center"}>相關連結</h4>
      <GridContainer justify={"center"}>
        <a href={"/111-2022-vacation-plan"}>民國111年 西元2022年 請假攻略</a>
      </GridContainer>
      <Footer />
    </div>
  );
}
