import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";
import "assets/css/bootstrap/bootstrap.min.css"
import "./index.scss"

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import Blog1 from "views/Blog/111-2022-calendar.js";
import Blog2 from "views/Blog/111-2022-vacation-plan.js";
import Blog3 from "views/Blog/republicera-bc-converter.js";
import Blog4 from "views/Blog/110-2021-calendar.js";
import LoginPage from "views/LoginPage/LoginPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/*<Route path="/landing-page" component={LandingPage} />*/}
      <Route path="/111-2022-calendar" component={Blog1} />
      <Route path="/110-2021-calendar" component={Blog4} />
      <Route path="/111-2022-vacation-plan" component={Blog2} />
      <Route path="/republicera-bc-converter" component={Blog3} />
      {/*<Route path="/login-page" component={LoginPage} />*/}
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
